import axios from "axios";
import BASE_URL from "../../data/constants";

export const prepareInvoicesOnlyKz = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/invoices_prepare/kz/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const prepareInvoicesOnlyNotKz = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/invoices_prepare/not_kz/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const prepareInvoicesAll = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/invoices_prepare/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const createInvoicesOnlyKz = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/invoices_create/kz/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const createInvoicesOnlyNotKz = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/invoices_create/not_kz/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const createInvoicesAll = async (data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/invoices_create/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};

export const pullOutData = async (url: string, data: Record<string, any> = {}, signal: any = null) => {
    try {
        const res = await axios.get(`${BASE_URL}/functions/${url}/`, { signal });
        return await res.data;
    } catch (e) {
        console.error(e);
        throw e;
    }
};
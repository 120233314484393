import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import axios from "axios";
import { useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import BASE_URL from "../../../../../../data/constants";
import ValueInputField from "../../../../../../UI/Inputs/ValueInputField";
import ContentContainer from "../../../../../../components/ContentContainer/ContentContainer";
import CustomButton from "../../../../../../UI/Buttons/CustomButton";
import { AuthContext } from "../../../../../../context/AuthContext";
import Restricted from "../../../../../../components/Restricted";
import {handleChangeField} from "../../../../services/helpers";
import SelectInputField from "../../../../../../UI/Inputs/SelectInputField";

const AccountPage = () => {
    const { setNotification } = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [referral, setReferral] = useState("");
    const [referralItems, setReferralItems] = useState([]);
    const { t } = useTranslation();
    const { id } = useParams();
    const getAccount = async () => {
        try {
            const res = await axios.get(`${BASE_URL}/clients/${id}/login_data/`);
            return await res.data;
        } catch (err) {
            console.log(err);
            return err;
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const res = await Promise.all([
                    await getAccount(),
                    await axios.get(`${BASE_URL}/properties/user_list/`)
                ])
                setUsername(res[0].username);
                setReferral(res[0].referral);
                setReferralItems(res[1].data);
            } catch (err) {
                setNotification({
                    open: true,
                    message: t("error.reset_error"),
                    type: "error",
                });
            }
        })();
    }, []);

    const handleResetPassword = async () => {
        try {
            const url = `clients/${id}/reset_password/`;
            const res = await axios.get(url);
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: unknown) {
            setNotification({
                open: true,
                message: t("error.unexpected"),
                type: "error",
            });
        }
    };

    const handleAddReferral = async () => {
        try {
            const url = `clients/${id}/referral/`;
            const res = await axios.post(url, {
                username: referral
            });
            setNotification({
                open: true,
                message: res.data.message,
                type: "success",
            });
        } catch (err: unknown) {
            setNotification({
                open: true,
                message: t("error.reset_error"),
                type: "error",
            });
        }
    };
    console.log("referral", referral);
    return (
        <ContentContainer>
            <Box
                sx={{
                    p: "3rem",
                    background: "#fff",
                    width: "fit-content",
                    display: "flex",
                    gap: "1rem",
                    flexDirection: "column"
                }}
            >
                <Box sx={{display: "flex", gap: "1rem"}}>
                    <ValueInputField Icon={<PersonIcon />} label="login" value={username} />
                    <Restricted to="canEdit">
                        <CustomButton style={{ height: "56px" }} Icon={<RefreshIcon style={{ color: "white" }} />} label="button.reset_password" onChange={handleResetPassword} />
                    </Restricted>
                </Box>

                <Box sx={{display: "flex", gap: "1rem"}}>
                    <SelectInputField
                        key="type-input"
                        // style={{ width: "100%" }}
                        value={referral}
                        label="Пригласивший пользователь"
                        items={referralItems}
                        onChange={(value) => setReferral(value)}
                    />
                    <Restricted to="canEdit">
                        <CustomButton style={{ height: "56px"}} Icon={<RefreshIcon style={{ color: "white" }} />} label="button.add" onChange={handleAddReferral} />
                    </Restricted>
                </Box>
            </Box>


        </ContentContainer>
    );
};

export default AccountPage;

import { Box, Checkbox, Divider, IconButton, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import React, {ChangeEvent, FC, ReactElement, useContext} from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { actionIcons, defaultCellStyle, pinnedColumn, StatusColors, sticky } from "../../constants";
import BASE_URL from "../../../../data/constants";
import {Header, Row} from "../../types";
import { RowType, StatusKey } from "./type";
import CustomButton from "../../../../UI/Buttons/CustomButton";
import {AuthContext} from "../../../../context/AuthContext";

const RenderRow: FC<RowType> = ({
    table_name = "",
    sortedRows,
    row,
    row_index,
    tableActions,
    selectedRows,
    handleAction,
    visibleHeaders,
    handleViewClientDetails,
    tempRow,
    rowDense,
    rowNumber,
    rowPinLeft,
}) => {
    const { setNotification } = useContext(AuthContext);
    const renderTableCell = (header: Header, colSpan: number, index: number, pinLeft: number) => {
        let tableCell: ReactElement;
        const value: any = row[header.key] !== undefined && row[header.key] !== null && row[header.key] !== "" ? row[header.key] : "-";
        const textStyle = {
            color: "#3A3F48",
            fontSize: "1rem",
            fontWeight: "400",
            textWrap: "wrap",
            whiteSpace: "break-spaces",
        };

        const addToClipBoard = (value: string | number) => {
            if (header.is_buffer) {
                if (navigator) {
                    navigator.clipboard.writeText(String(value));
                    setNotification({
                        open: true,
                        message: "Скопировано в буфер обмена!",
                        type: "success"
                    })
                }
            }
        };

        const getUrlPrevCell = (type: string, value: string | Record<string, any>, onClick: (action: string, value: Row | string, event: React.MouseEvent | ChangeEvent) => void = () => {}) => {
            let newValue = value;
            if (type === "download") {
                newValue = (value as Record<string, any>).value;
            }
            return (
                <Tooltip
                    PopperProps={{
                        sx: {
                            "& .MuiTooltip-tooltip": {
                                border: "none",
                                background: "#fff",
                                width: "380px",
                                p: "0",
                            },
                        },
                    }}
                    title={
                        newValue &&
                        (type === "pdf" || type === "download" ? (
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                <Box
                                    sx={{
                                        width: "306px",
                                    }}
                                >
                                    <Viewer fileUrl={`${BASE_URL}${newValue}`} />
                                </Box>
                            </Worker>
                        ) : (
                            <Box sx={{ width: "306px", maxHeight: "350px", overflow: "scroll" }}>
                                <img src={`${BASE_URL}${newValue}`} alt="preview" style={{ height: "100%", width: "100%", objectFit: "cover" }} />
                            </Box>
                        ))
                    }
                >
                    { type === "download"
                        ? (
                            <Typography
                                sx={{
                                    ...textStyle,
                                    cursor: "pointer",
                                    fontWeight: "500",
                                    color: "#2C90F4",
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                                onClick={(e) => onClick("download", (value as Record<string, any>).endpoint, e)}
                            >
                                Download
                            </Typography>
                        ) : (
                            <Link to={`${BASE_URL}${newValue}`} target="_blank" style={{ textDecoration: "none" }}>
                                <Typography
                                    sx={{
                                        ...textStyle,
                                        maxWidth: "236px",
                                        display: "-webkit-box",
                                        "-webkit-line-clamp": "2",
                                        "-webkit-box-orient": "vertical",
                                        overflow: "hidden",
                                        maxHeight: "48px",
                                        textOverflow: "ellipsis",
                                        color: "#2C90F4",
                                    }}
                                >
                                    {newValue.split("/")[3]}
                                </Typography>
                            </Link>
                        )
                    }
                </Tooltip>
            )
        };

        switch (header.type) {
            case "string":
                tableCell = <Typography sx={textStyle} onClick={() => addToClipBoard(value)}>{value}</Typography>;
                break;
            case "number":
                tableCell = (
                    <Typography sx={textStyle} onClick={() => addToClipBoard(value)}>
                        {value.toLocaleString("ru-RU", {
                            maximumFractionDigits: 3,
                        })}
                    </Typography>
                );
                break;
            case "pdf":
                tableCell = getUrlPrevCell("pdf", value);
                break;
            case "boolean":
                tableCell = (
                    <Typography
                        sx={{
                            ...textStyle,
                            display: "flex",
                            alignItems: "center",
                            gap: ".25rem",
                        }}
                    >
                        {value ? (
                            <>
                                <CheckIcon style={{ color: "green" }} />
                                Yes
                            </>
                        ) : (
                            <>
                                <CloseIcon style={{ color: "red" }} />
                                No
                            </>
                        )}
                    </Typography>
                );
                break;
            case "status":
                const status = StatusColors[(value.key as StatusKey) || "wait"];
                tableCell = (
                    <Box
                        sx={{
                            backgroundColor: status.backgroundColor,
                            borderRadius: ".75rem",
                            display: "flex",
                            gap: ".5rem",
                            alignItems: "center",
                            padding: "0 .5rem",
                            width: "fit-content",
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: status.color,
                                borderRadius: "50%",
                                width: ".5rem",
                                height: ".5rem",
                            }}
                        />
                        <Typography sx={textStyle}>{value.value}</Typography>
                    </Box>
                );
                break;
            case "download":
                tableCell = getUrlPrevCell("download", value, handleAction);
                break;
            case "upload":
                if (value.value) {
                    const urlArr = value.value.split(".");
                    const type = urlArr[urlArr.length - 1];
                    tableCell = getUrlPrevCell(type, value.value);
                } else {
                    tableCell = (
                        <Typography
                            sx={{
                                ...textStyle,
                                color: "#2C90F4",
                                fontWeight: "500",
                                cursor: "pointer",
                                "&:hover": {
                                    textDecoration: "underline",
                                },
                            }}
                            onClick={(e) => handleAction("upload", value.endpoint, e)}
                        >
                            Upload
                        </Typography>
                    );
                }
                break;
            case "month":
                tableCell = (
                    <Typography sx={textStyle}>
                        {dayjs()
                            .month(value - 1)
                            .format("MMMM YYYY")}
                    </Typography>
                );
                break;
            case "url":
                tableCell = (
                    <Link to={value} target="_blank" style={{ textDecoration: "none" }}>
                        <Typography
                            sx={{
                                ...textStyle,
                                // maxWidth: "236px",
                                display: "-webkit-box",
                                "-webkit-line-clamp": "1",
                                "-webkit-box-orient": "vertical",
                                overflow: "hidden",
                                maxHeight: "48px",
                                textOverflow: "ellipsis",
                                color: "#2C90F4",
                            }}
                        >
                            {value}
                        </Typography>
                    </Link>
                );
                break;
            default:
                tableCell = <Typography sx={textStyle}>{value}</Typography>;
                break;
        }
        return (
            <TableCell
                rowSpan={colSpan}
                key={index}
                sx={{
                    ...defaultCellStyle,
                    height: rowDense,
                    maxHeight: rowDense,
                    width: Number(header.width),
                    minWidth: 120,
                    ...(header.pin ? { ...pinnedColumn, left: pinLeft } : {}),
                }}
            >
                <Box
                    sx={{
                        width: Number(header.width),
                        minWidth: 120,
                        p: "0 .75rem",
                        minHeight: 24,
                    }}
                >
                    {tableCell}
                </Box>
                <Divider
                    sx={{
                        position: "absolute",
                        top: "25%",
                        right: "0",
                        height: "24px",
                        margin: "auto 0",
                    }}
                    orientation="vertical"
                />
            </TableCell>
        );
    };
    return (
        <TableRow
            key={row.id}
            sx={{
                position: "relative",
                cursor: tableActions.view ? "pointer" : "",
                backgroundColor: "#fff",
                boxShadow: table_name !== "BLOCKS" ? "0px 0px 12px 0px rgba(0, 0, 0, 0.08)" : "none",
                ":hover": {
                    backgroundColor: "var(--main-purple-hover-color)",
                },
            }}
            onClick={(e) => (tableActions.view ? handleViewClientDetails(row, e) : "")}
        >
            {tableActions.pick_row && (
                <TableCell
                    sx={{
                        ...defaultCellStyle,
                        background: "inherit",
                        width: "71px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Checkbox sx={{ mr: ".25rem" }} checked={selectedRows.includes(row.id)} onClick={(event) => handleAction("select", row, event)} />
                    <p
                        style={{
                            borderRight: "1px solid var(--main-divider-color)",
                            height: "24px",
                        }}
                    />
                </TableCell>
            )}
            <TableCell
                rowSpan={1}
                sx={{
                    ...defaultCellStyle,
                    width: 50,
                }}
            >
                <Box
                    sx={{
                        p: ".75rem",
                        borderRight: "1px solid var(--main-divider-color)",
                        height: "24px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            color: "#3A3F48",
                            fontSize: "1rem",
                            fontWeight: "400",
                        }}
                    >
                        {rowNumber}
                    </Typography>
                </Box>
            </TableCell>
            {visibleHeaders.map((header: Header, index: number) => {
                if (row_index !== 0 && header.combine && row[header.key] === sortedRows[tempRow.value][header.key]) {
                    return null;
                }
                tempRow.value = row_index;

                let colSpan: number;
                if (header.combine) {
                    const currentRowData = row[header.key];
                    const nextRowData = sortedRows[row_index + 1] && sortedRows[row_index + 1][header.key];
                    if (currentRowData === nextRowData) {
                        const arr = sortedRows.slice(row_index);
                        colSpan = arr.findIndex((r: any) => r[header.key] !== currentRowData);
                        if (colSpan === -1) {
                            colSpan = arr.length;
                        }
                    } else {
                        colSpan = 1;
                    }
                } else {
                    colSpan = 1;
                }
                const pinLeft = rowPinLeft;
                if (header.pin) {
                    rowPinLeft += Number(header.width);
                }
                return renderTableCell(header, colSpan, index, pinLeft);
            })}
            {tableActions.row_actions && (
                <TableCell
                    sx={{
                        ...defaultCellStyle,
                        ...sticky,
                        display: "flex",
                        gap: "12px",
                        flexDirection: "row",
                        height: rowDense,
                        alignItems: "center",
                        boxShadow: row.actions ? "-4px 0px 12px 0px rgba(0, 0, 0, 0.08)" : "none",
                        p: "0 .75rem",
                    }}
                >
                    {row.actions &&
                        row.actions.map((item: any) => {
                            const icon = actionIcons[item.action];
                            if (item.action === "view") {
                                return <CustomButton Icon={icon} label="More" onChange={(event) => handleAction(item.action, row, event)} />;
                            }
                            return (
                                <Tooltip title={item.label}>
                                    <IconButton
                                        sx={{
                                            borderRadius: "0",
                                            padding: "0",
                                        }}
                                        onClick={(event) => handleAction(item.action, row, event)}
                                    >
                                        {icon}
                                    </IconButton>
                                </Tooltip>
                            );
                        })}
                </TableCell>
            )}
        </TableRow>
    );
};

export default RenderRow;

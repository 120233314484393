import React, { FC } from "react";
import {FormControl, InputLabel, MenuItem, TextField, FormHelperText, SxProps, Theme} from "@mui/material";
import {FieldError} from "react-hook-form";
import { OptionObjectType } from "../../services/common-types";
import {parseLabelKey} from "../../utils/helpers";

type SelectFieldProps = {
    label: string;
    value: string;
    sx?: SxProps<Theme>;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    options: Array<string | OptionObjectType>;
    errors?: FieldError | { message?: string } | any;
    readOnly?: boolean;
};

const SelectField: FC<SelectFieldProps> = ({
    label,
    value,
    onChange,
    options,
    errors={},
    readOnly = false,
    sx={}
}) => {
    const isError = errors && errors.message;
    const styles = {
        maxWidth: "360px",width: "100%",
        ...sx,
    };
    return (
        <FormControl sx={styles} error={isError}>
            <TextField
                select
                label={label}
                value={value}
                onChange={onChange}
                InputProps={{
                    readOnly,
                }}
                variant="outlined"
                sx={{
                    "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "2px solid",
                            borderColor: "primary.main",
                        },
                    },
                    ".MuiOutlinedInput-notchedOutline": isError ? { borderColor: "error.main" } : {},
                    "& .MuiInputLabel-root": isError ? { color: "error.main" } : {},
                }}
            >
                {options.map((item, index) => {
                    const { label, key } = parseLabelKey(item)
                    return (
                        <MenuItem key={index} value={key}>
                            {label}
                        </MenuItem>
                    );
                })}
            </TextField>
            {isError && <FormHelperText sx={{mt: "0px", lineHeight: "14px"}}>{errors?.message}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import {RouteType} from "./config";
import {ReactComponent as ClientsIcon} from "../assets/images/icons/clientsIcon.svg";
import {ReactComponent as PartnersIcon} from "../assets/images/icons/handshakeIcon.svg";
import {ReactComponent as SettingsIcon} from "../assets/images/icons/settingsIcon.svg";
import {ReactComponent as HouseManIcon} from "../assets/images/icons/common/house-man.svg";
import {ReactComponent as MoneyIcon} from "../assets/images/icons/common/moneyIcon.svg";
import {ReactComponent as CurrencyRatesIcon} from "../assets/images/icons/Currency rate.svg";
import {ReactComponent as TransferMethodsIcon} from "../assets/images/icons/Transfer methods.svg";
import {ReactComponent as CountriesIcon} from "../assets/images/icons/Countries.svg";
import {ReactComponent as SystemIcon} from "../assets/images/icons/Systems.svg";
import {ReactComponent as GoogleIcon} from "../assets/images/icons/common/googleIcon.svg";
import {ReactComponent as NetworkIcon} from "../assets/images/icons/common/networkIcon.svg";
import {ReactComponent as MoneyCutIcon} from "../assets/images/icons/common/cutMoney.svg";
import {ReactComponent as NewPlacementsIcon} from "../assets/images/icons/common/newPlacements.svg";
import {ReactComponent as ManagerIcon} from "../assets/images/icons/manager.svg";
import {ReactComponent as DocumentIcon} from "../assets/images/icons/common/documentIcon.svg";
import {ReactComponent as FileIcon} from "../assets/images/icons/common/fileIcon.svg";
import {ReactComponent as AdIcon} from "../assets/images/icons/common/ad.svg";
import {ReactComponent as GiftIcon} from "../assets/images/icons/gift.svg";
import PartnersPageLayout from "../pages/PartnersPage/PartnersPageLayout";
import SuppliersPage from "../pages/PartnersPage/pages/SuppliersPage";
import AccountPage from "../pages/ClientsPage/pages/ClientViewPage/pages/AccountPage";
import ClientPageLayout from "../pages/ClientsPage/pages/ClientViewPage";
import PlatformsPage from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage";
import ClientCreatePage from "../pages/ClientsPage/pages/ClientCreatePage";
import PaymentDetailsCreateOrEdit from "../pages/ClientsPage/pages/ClientViewPage/pages/PaymentDetailsPage/pages/PaymentDetailsCreateOrEdit";
import ContactsCreateOrEdit from "../pages/ClientsPage/pages/ClientViewPage/pages/ContactsPage/pages/ContactsCreateOrEdit";
import AgreementCreateOrEdit from "../pages/ClientsPage/pages/ClientViewPage/pages/AgreementPage/pages/AgreementCreateOrEdit";
import FeesCreateOrEdit from "../pages/ClientsPage/pages/ClientViewPage/pages/FeesPage/pages/FeesCreateOrEdit";
import ReportsPage from "../pages/StatisticPage";
import FeesViewPage from "../pages/ClientsPage/pages/ClientViewPage/pages/FeesPage/pages/FeesViewPage";
import ContactViewPage from "../pages/ClientsPage/pages/ClientViewPage/pages/ContactsPage/pages/ContactViewPage";
import AgreementViewPage from "../pages/ClientsPage/pages/ClientViewPage/pages/AgreementPage/pages/AgreementViewPage";
import PaymentDetailsViewPage from "../pages/ClientsPage/pages/ClientViewPage/pages/PaymentDetailsPage/pages/PaymentDetailsViewPage";
import PlatformViewLayout from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage/pages/PlatformView";
import {ReactComponent as DashboardIcon} from "../assets/images/icons/common/dashboardIcon.svg";
import SupplierLayoutPage from "../pages/PartnersPage/pages/SuppliersPage/pages/SupplierViewPage";
import SupplierPage from "../pages/PartnersPage/pages/SuppliersPage/pages/SupplierViewPage/pages/SupplierPage";
import BonusPage from "../pages/PartnersPage/pages/SuppliersPage/pages/SupplierViewPage/pages/BonusPage";
import SupplierCreatePage from "../pages/PartnersPage/pages/SuppliersPage/pages/SupplierCreatePage";
import LegalEntitiesPage from "../pages/PartnersPage/pages/LegalEntitiesPage";
import BonusCreate from "../pages/PartnersPage/pages/SuppliersPage/pages/SupplierViewPage/pages/BonusPage/pages/BonusCreate";
import BonusEdit from "../pages/PartnersPage/pages/SuppliersPage/pages/SupplierViewPage/pages/BonusPage/pages/BonusEdit";
import LegalEntityCreate from "../pages/PartnersPage/pages/LegalEntitiesPage/pages/LegalEntityCreate";
import LegalEntityLayout from "../pages/PartnersPage/pages/LegalEntitiesPage/pages/LegalEntityView";
import LegalEntityEdit from "../pages/PartnersPage/pages/LegalEntitiesPage/pages/LegalEntityView/pages/LegalEntityEdit";
import DashboardPage from "../pages/DashboardPage";
import PlatformEdit from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage/pages/PlatformView/pages/PlatformEdit";
import PlatformCreate from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage/pages/PlatformCreateOrEdit";
import Blocks from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage/pages/PlatformView/pages/Blocks";
import InvoicesPage from "../pages/ClientsPage/pages/ClientViewPage/pages/InvoicesPage";
import BanksPage from "../pages/PartnersPage/pages/LegalEntitiesPage/pages/LegalEntityView/pages/BanksPage";
import BanksCreate from "../pages/PartnersPage/pages/LegalEntitiesPage/pages/LegalEntityView/pages/BanksPage/pages/BanksCreate";
import BanksEdit from "../pages/PartnersPage/pages/LegalEntitiesPage/pages/LegalEntityView/pages/BanksPage/pages/BanksEdit";
import TransferMethodsPage from "../pages/PartnersPage/pages/TransferMethodsPage";
import TransferMethodsEdit from "../pages/PartnersPage/pages/TransferMethodsPage/pages/TransferMethodsView/pages/TransferMethodsEdit";
import TransferMethodsLayout from "../pages/PartnersPage/pages/TransferMethodsPage/pages/TransferMethodsView";
import CurrencyRatesPage from "../pages/PartnersPage/pages/CurrencyRatesPage";
import CountriesPage from "../pages/PartnersPage/pages/CountriesPage";
import SystemPage from "../pages/PartnersPage/pages/SystemPage";
import CurrenciesPage from "../pages/PartnersPage/pages/CurrenciesPage";
import CurrenciesCreate from "../pages/PartnersPage/pages/CurrenciesPage/pages/CurrenciesCreate";
import CurrenciesLayout from "../pages/PartnersPage/pages/CurrenciesPage/pages/CurrenciesView";
import CurrenciesEdit from "../pages/PartnersPage/pages/CurrenciesPage/pages/CurrenciesView/pages/CurrenciesEdit";
import SystemCreate from "../pages/PartnersPage/pages/SystemPage/pages/SystemCreate";
import SystemLayout from "../pages/PartnersPage/pages/SystemPage/pages/SystemView";
import SystemEdit from "../pages/PartnersPage/pages/SystemPage/pages/SystemView/pages/SystemEdit";
import CountriesEdit from "../pages/PartnersPage/pages/CountriesPage/pages/CountriesView/pages/CountriesEdit";
import CountriesCreate from "../pages/PartnersPage/pages/CountriesPage/pages/CountriesCreate";
import CountriesLayout from "../pages/PartnersPage/pages/CountriesPage/pages/CountriesView";
import CurrencyRatesLayout from "../pages/PartnersPage/pages/CurrencyRatesPage/pages/CurrencyRatesView";
import CurrencyRatesEdit from "../pages/PartnersPage/pages/CurrencyRatesPage/pages/CurrencyRatesView/pages/CurrencyRatesEdit";
import CurrencyRatesCreate from "../pages/PartnersPage/pages/CurrencyRatesPage/pages/CurrencyRatesCreate";
import TransferMethodsCreate from "../pages/PartnersPage/pages/TransferMethodsPage/pages/TransferMethodsCreate";
import GoogleLayout from "../pages/GooglePage";
import NetworkCodes from "../pages/GooglePage/pages/NetworkCodes";
import GoogleDeduction from "../pages/GooglePage/pages/GoogleDeduction";
import NetworkCodeEdit from "../pages/GooglePage/pages/NetworkCodes/pages/NetworkCodeEdit";
import GoogleDeductionEdit from "../pages/GooglePage/pages/GoogleDeduction/pages/GoogleDeductionEdit";
import NewPlacements from "../pages/NewPlacementsPage";
import PlacementEdit from "../pages/NewPlacementsPage/pages/PlacementEdit";
import {ROLES} from "../data/permission-maps";
import ManagersPage from "../pages/ManagersPage";
import ManagerLayout from "../pages/ManagersPage/pages/ManagersView";
import BonusesPage from "../pages/ManagersPage/pages/ManagersView/pages/BonusesPage";
import ManagerCreate from "../pages/ManagersPage/pages/ManagersCreate";
import ManagerEdit from "../pages/ManagersPage/pages/ManagersView/pages/ManagerEdit";
import ManagerBonusCreate from "../pages/ManagersPage/pages/ManagersView/pages/BonusesPage/pages/BonusCreate";
import ManagerBonusEdit from "../pages/ManagersPage/pages/ManagersView/pages/BonusesPage/pages/BonusEdit";
import DocumentsLayout from "../pages/DocumentsPage";
import PayoutPage from "../pages/DocumentsPage/pages/PayoutPage";
import NotificationPage from "../pages/NotificationsPage/pages/Notification";
import NotificationPageLayout from "../pages/NotificationsPage/NotificationsPageLayout";
import NotificationIconBadged from "../components/NotificationMenu/NotificationIconBadged";
import ArchivePage from "../pages/NotificationsPage/pages/Archive";
import FeesView from "../pages/ClientsPage/views/FeesView";
import ClientsView from "../pages/ClientsPage/views/ClientsView";
import AccountView from "../pages/ClientsPage/views/AccountView";
import PaymentDetailsView from "../pages/ClientsPage/views/PaymentDetailsView";
import ContactsView from "../pages/ClientsPage/views/ContactsView";
import AgreementsView from "../pages/ClientsPage/views/AgreementsView";
import PlacementFees from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage/pages/PlatformView/pages/Fees";
import PlacementFeeCreateEditView from "../pages/ClientsPage/pages/ClientViewPage/pages/PlatformsPage/pages/PlatformView/pages/Fees/PlacementFeeCreateEditView";
import ProductsPageLayout from "../pages/ProductsPage";
import {PerfectVideoYDPage} from "../pages/ProductsPage/pages/PVW_YD";
import PerfectVideoYDDetails from "../pages/ProductsPage/pages/PVW_YD/pages/PerfectVideoYDDetails";
import {StickyWidgetPage} from "../pages/ProductsPage/pages/sticky_widget";
import { RecWidgetPage } from "../pages/ProductsPage/pages/recWidget";
import {PerfectAdUnitPage} from "../pages/ProductsPage/pages/PAU";
import PerfectAdUnitWidgetDetails from "../pages/ProductsPage/pages/PAU/pages";
import MoneyPage from "../pages/DocumentsPage/pages/MoneyPage";
import StickyWidgetDetails from "../pages/ProductsPage/pages/sticky_widget/pages/stickyWidgetDetails";
import RecWidgetDetails from "../pages/ProductsPage/pages/recWidget/pages/recWidgetDetails";
import {SideStickWidgetPage} from "../pages/ProductsPage/pages/SideStick";
import SideStickWidgetDetails from "../pages/ProductsPage/pages/SideStick/pages/SideStickWidgetDetails";
import {WaterfallPVW} from "../pages/ProductsPage/pages/WaterfallPVW";
import WaterfallPVWDetails from "../pages/ProductsPage/pages/WaterfallPVW/WaterfallPVWDetails";
import {InImage} from "../pages/ProductsPage/pages/InImage";
import InImageDetails from "../pages/ProductsPage/pages/InImage/InImageDetails";
import ReferralSystemLayoutPage from "../pages/ReferralSystem";
import DetailsPage from "../pages/ReferralSystem/pages/DetailsPage";
import MyReferralsPage from "../pages/ReferralSystem/pages/MyReferralsPage";
import Functions from "../pages/Functions";

const appRoutes: RouteType[] = [
    {
        index: true,
        path: "/notifications",
        element: <NotificationPageLayout />,
        state: "notifications",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "notifications",
            icon: <NotificationIconBadged />,
        },
    },
    {
        path: "/notifications/notification",
        element: <NotificationPage />,
        state: "notifications.notification",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
    },
    {
        path: "/notifications/archive",
        element: <ArchivePage />,
        state: "notifications.archive",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
    },
    {
        index: true,
        path: "/",
        element: <DashboardPage />,
        state: "home",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "dashboard",
            icon: <DashboardIcon />,
        },
    },
    {
        path: "/clients",
        element: <ClientsView />,
        state: "clients",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: {
                admin: "clients",
                manager: "clients",
                client: "my_accounts",
            },
            icon: <ClientsIcon />,
        },
    },
    {
        path: "/clients/:id",
        element: <ClientPageLayout />,
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
        state: "clients",
        child: [
            {
                path: "/clients/:id/client",
                element: <AccountView />,
                state: "clients.client",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/account_details",
                element: <AccountPage />,
                state: "clients.account_details",
                permissions: [ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/payment_details",
                element: <PaymentDetailsView />,
                state: "clients.payment_details",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/contacts",
                element: <ContactsView />,
                state: "clients.contacts",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/agreements",
                element: <AgreementsView />,
                state: "clients.agreements",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/conditions",
                element: <FeesView />,
                state: "clients.conditions",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/platforms",
                element: <PlatformsPage />,
                state: "clients.platforms",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/invoices",
                element: <InvoicesPage />,
                state: "clients.invoices",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/clients/:id/payment_details/:paymentId",
        element: <PaymentDetailsViewPage />,
        state: "clients.payment-details.view",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/payment_details/:paymentId/edit",
        element: <PaymentDetailsCreateOrEdit />,
        state: "clients.payment-details.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/payment_details/create",
        element: <PaymentDetailsCreateOrEdit />,
        state: "clients.payment-details.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/contacts/:contactId",
        element: <ContactViewPage />,
        state: "clients.contacts.view",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/contacts/:contactId/edit",
        element: <ContactsCreateOrEdit />,
        state: "clients.agreements.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/contacts/create",
        element: <ContactsCreateOrEdit />,
        state: "clients.contacts.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/agreements/:contractId",
        element: <AgreementViewPage />,
        state: "clients.agreements.view",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/agreements/:contractId/edit",
        element: <AgreementCreateOrEdit />,
        state: "clients.agreements.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/agreements/create",
        element: <AgreementCreateOrEdit />,
        state: "clients.agreements.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/conditions/:conditionId",
        element: <FeesViewPage />,
        state: "clients.conditions.view",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/conditions/:conditionId/edit",
        element: <FeesCreateOrEdit />,
        state: "clients.conditions.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/conditions/create",
        element: <FeesCreateOrEdit />,
        state: "clients.conditions.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/platforms/create",
        element: <PlatformCreate />,
        state: "clients.platforms.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/create",
        element: <ClientCreatePage />,
        state: "clients",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/platforms/:platformId",
        element: <PlatformViewLayout />,
        state: "clients.platforms",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/clients/:id/platforms/:platformId/edit",
                element: <PlatformEdit />,
                state: "clients.platforms.edit",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/platforms/:platformId/blocks",
                element: <Blocks />,
                state: "clients.platforms.blocks",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
            {
                path: "/clients/:id/platforms/:platformId/conditions",
                element: <PlacementFees />,
                state: "clients.platforms.conditions",
                permissions: [ROLES.client, ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/clients/:id/platforms/:platformId/conditions/:conditionId",
        element: <PlacementFeeCreateEditView mode="view" />,
        state: "clients.platforms.conditions",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/platforms/:platformId/conditions/create",
        element: <PlacementFeeCreateEditView mode="create" />,
        state: "clients.platforms.conditions",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/clients/:id/platforms/:platformId/conditions/:conditionId/edit",
        element: <PlacementFeeCreateEditView mode="edit" />,
        state: "clients.platforms.conditions",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        element: <PartnersPageLayout />,
        state: "properties",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "partners",
            icon: <PartnersIcon />,
        },
        child: [
            {
                path: "/properties/suppliers",
                element: <SuppliersPage />,
                state: "properties.suppliers",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "suppliers",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/properties/llp",
                element: <LegalEntitiesPage />,
                state: "properties.llp",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "legal_entities",
                    icon: <HouseManIcon />,
                },
            },
            {
                path: "/properties/tm",
                element: <TransferMethodsPage />,
                state: "properties.tm",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "transfer_methods",
                    icon: <TransferMethodsIcon />,
                },
            },
            {
                path: "/properties/cr",
                element: <CurrencyRatesPage />,
                state: "properties.cr",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "currency_rates",
                    icon: <CurrencyRatesIcon />,
                },
            },
            {
                path: "/properties/countries",
                element: <CountriesPage />,
                state: "properties.countries",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "countries",
                    icon: <CountriesIcon />,
                },
            },
            {
                path: "/properties/system",
                element: <SystemPage />,
                state: "properties.system",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "systems",
                    icon: <SystemIcon />,
                },
            },
            {
                path: "/properties/currencies",
                element: <CurrenciesPage />,
                state: "properties.currencies",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "currencies",
                    icon: <MoneyIcon />,
                },
            },
        ],
    },
    {
        path: "/properties/currency-rates",
        element: <CurrencyRatesPage />,
        state: "properties.cr",
        permissions: [ROLES.client],
        sidebarProps: {
            displayText: "currency_rates",
            icon: <CurrencyRatesIcon />,
        },
    },
    {
        path: "/google",
        element: <GoogleLayout />,
        state: "google",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            icon: <GoogleIcon />,
            displayText: "Google",
        },
        child: [
            {
                path: "/google/network_codes",
                element: <NetworkCodes />,
                state: "google.network_codes",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    icon: <NetworkIcon />,
                    displayText: "network_codes",
                },
            },
            {
                path: "/google/google_deductions",
                element: <GoogleDeduction />,
                state: "google.google_deductions",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    icon: <MoneyCutIcon />,
                    displayText: "google_deductions",
                },
            },
        ],
    },
    {
        path: "/new_placements",
        element: <NewPlacements />,
        state: "new_placements",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            icon: <NewPlacementsIcon />,
            displayText: "new_placements",
        },
    },
    {
        path: "/new_placements/:id/edit",
        element: <PlacementEdit />,
        state: "google.new_placements.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/documents",
        element: <DocumentsLayout />,
        state: "documents",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "documents",
            icon: <DocumentIcon />,
        },
        child: [
            {
                path: "/documents/invoices",
                element: <InvoicesPage isTopBar />,
                state: "documents.invoices",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "invoices",
                    icon: <FileIcon />,
                },
            },
            {
                path: "/documents/payout",
                element: <PayoutPage />,
                state: "documents.payout",
                permissions: [ROLES.accountant, ROLES.admin],
                sidebarProps: {
                    displayText: "payout",
                    icon: <AccountBalanceIcon style={{ color: "#3A3F48" }} />,
                },
            },
            {
                path: "/documents/money",
                element: <MoneyPage />,
                state: "documents.money",
                permissions: [ROLES.accountant, ROLES.admin],
                sidebarProps: {
                    displayText: "pages.dashboard.money",
                    icon: <AttachMoneyIcon style={{ color: "#3A3F48" }} />,
                },
            },
        ],
    },
    {
        path: "/invoices",
        element: <InvoicesPage isTopBar />,
        state: "documents.invoices",
        permissions: [ROLES.client],
        sidebarProps: {
            displayText: "invoices",
            icon: <FileIcon />,
        },
    },
    {
        path: "/users/managers",
        element: <ManagersPage />,
        state: "managers",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "managers",
            icon: <ManagerIcon />,
        },
    },
    {
        path: "/reports",
        element: <ReportsPage />,
        state: "reports",
        permissions: [ROLES.client, ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "statistics",
            icon: <EqualizerIcon style={{ color: "#3A3F48" }} />,
        },
    },
    {
        path: "/properties/suppliers/:id",
        element: <SupplierLayoutPage />,
        state: "properties.suppliers",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/suppliers/:id/supplier",
                element: <SupplierPage />,
                state: "properties.suppliers.supplier",
                permissions: [ROLES.admin, ROLES.manager],
            },
            {
                path: "/properties/suppliers/:id/bonus",
                element: <BonusPage />,
                state: "properties.suppliers.bonus",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/suppliers/:id/bonus/create",
        element: <BonusCreate />,
        state: "properties.suppliers.bonus.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/suppliers/:id/bonus/:bonusId/edit",
        element: <BonusEdit />,
        state: "properties.suppliers.bonus.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/suppliers/create",
        element: <SupplierCreatePage />,
        state: "properties.suppliers",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/llp/create",
        element: <LegalEntityCreate />,
        state: "properties.llp",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/llp/:id",
        element: <LegalEntityLayout />,
        state: "properties.llp",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/llp/:id/edit",
                element: <LegalEntityEdit />,
                state: "properties.llp.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
            {
                path: "/properties/llp/:id/banks",
                element: <BanksPage />,
                state: "properties.llp.banks",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/llp/:id/banks/create",
        element: <BanksCreate />,
        state: "properties.llp.banks.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/llp/:id/banks/:bankId/edit",
        element: <BanksEdit />,
        state: "properties.llp.banks.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/tm/create",
        element: <TransferMethodsCreate />,
        state: "properties.tm",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/tm/:id",
        element: <TransferMethodsLayout />,
        state: "properties.tm",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/tm/:id/edit",
                element: <TransferMethodsEdit />,
                state: "properties.tm.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/tm/create",
        element: <TransferMethodsCreate />,
        state: "properties.tm",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/tm/:id",
        element: <TransferMethodsLayout />,
        state: "properties.tm",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/tm/:id/edit",
                element: <TransferMethodsEdit />,
                state: "properties.tm.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/cr/create",
        element: <CurrencyRatesCreate />,
        state: "properties.cr",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/cr/:id",
        element: <CurrencyRatesLayout />,
        state: "properties.cr",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/cr/:id/edit",
                element: <CurrencyRatesEdit />,
                state: "properties.cr.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/countries/create",
        element: <CountriesCreate />,
        state: "properties.countries",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/countries/:id",
        element: <CountriesLayout />,
        state: "properties.countries",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/countries/:id/edit",
                element: <CountriesEdit />,
                state: "properties.countries.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/system/create",
        element: <SystemCreate />,
        state: "properties.system",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/system/:id",
        element: <SystemLayout />,
        state: "properties.system",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/system/:id/edit",
                element: <SystemEdit />,
                state: "properties.system.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/properties/currencies/create",
        element: <CurrenciesCreate />,
        state: "properties.currencies",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/properties/currencies/:id",
        element: <CurrenciesLayout />,
        state: "properties.currencies",
        permissions: [ROLES.admin, ROLES.manager],
        child: [
            {
                path: "/properties/currencies/:id/edit",
                element: <CurrenciesEdit />,
                state: "properties.currencies.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/google/network_codes/:id/edit",
        element: <NetworkCodeEdit />,
        state: "google.network_codes.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/google/google_deductions/:id/edit",
        element: <GoogleDeductionEdit />,
        state: "google.google_deductions.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/users/managers/add",
        element: <ManagerCreate />,
        state: "managers",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/users/managers/:id",
        element: <ManagerLayout />,
        permissions: [ROLES.admin, ROLES.manager],
        state: "managers",
        child: [
            {
                path: "/users/managers/:id/edit",
                element: <ManagerEdit />,
                state: "managers.edit",
                permissions: [ROLES.admin, ROLES.manager],
            },
            {
                path: "/users/managers/:id/bonus",
                element: <BonusesPage />,
                state: "managers.bonus",
                permissions: [ROLES.admin, ROLES.manager],
            },
        ],
    },
    {
        path: "/users/managers/:id/bonus/create",
        element: <ManagerBonusCreate />,
        state: "managers.bonus.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/users/managers/:id/bonus/:bonusId/edit",
        element: <ManagerBonusEdit />,
        state: "managers.bonus.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        element: <ProductsPageLayout />,
        state: "products",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "Products",
            icon: <AdIcon />,
        },
        child: [
            {
                path: "/products/pvw_yd",
                element: <PerfectVideoYDPage />,
                state: "products.pvw_yd",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "PVW YD",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/products/pvw_waterfall",
                element: <WaterfallPVW />,
                state: "products.pvw_waterfall",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "Waterfall PVW",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/products/sticky",
                element: <StickyWidgetPage />,
                state: "products.sticky",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "Sticky Widget",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/products/side_sticky",
                element: <SideStickWidgetPage />,
                state: "products.side_sticky",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "Side Stick Widget",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/products/recwidget",
                element: <RecWidgetPage />,
                state: "products.recwidget",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "RecWidget",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/products/pau",
                element: <PerfectAdUnitPage />,
                state: "products.pau",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "PAU",
                    icon: <SettingsIcon />,
                },
            },
            {
                path: "/products/in_image",
                element: <InImage />,
                state: "products.in_image",
                permissions: [ROLES.admin, ROLES.manager],
                sidebarProps: {
                    displayText: "InImage",
                    icon: <SettingsIcon />,
                },
            },
        ],
    },
    {
        path: "/products/pvw_yd/create",
        element: <PerfectVideoYDDetails />,
        state: "products.pvw_yd.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/pvw_yd/:id/edit",
        element: <PerfectVideoYDDetails />,
        state: "products.pvw_yd.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/pvw_waterfall/create",
        element: <WaterfallPVWDetails />,
        state: "products.pvw_waterfall.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/pvw_waterfall/:id/edit",
        element: <WaterfallPVWDetails />,
        state: "products.pvw_waterfall.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/in_image/:id/edit",
        element: <InImageDetails />,
        state: "products.in_image.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/in_image/create",
        element: <InImageDetails />,
        state: "products.in_image.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/sticky/create",
        element: <StickyWidgetDetails />,
        state: "products.sticky.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/sticky/:id/edit",
        element: <StickyWidgetDetails />,
        state: "products.sticky.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/side_sticky/create",
        element: <SideStickWidgetDetails />,
        state: "products.side_sticky.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/side_sticky/:id/edit",
        element: <SideStickWidgetDetails />,
        state: "products.side_sticky.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/pau/:id/edit",
        element: <PerfectAdUnitWidgetDetails />,
        state: "products.pau.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/pau/create",
        element: <PerfectAdUnitWidgetDetails />,
        state: "products.pau.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/recwidget/create",
        element: <RecWidgetDetails />,
        state: "products.recwidget.create",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/products/recwidget/:id/edit",
        element: <RecWidgetDetails />,
        state: "products.recwidget.edit",
        permissions: [ROLES.admin, ROLES.manager],
    },
    {
        path: "/referral-system",
        element: <ReferralSystemLayoutPage />,
        state: "referral-system",
        permissions: [ROLES.admin, ROLES.manager, ROLES.client],
        sidebarProps: {
            displayText: "bonus",
            icon: <GiftIcon />,
        },
        status: "new"
    },
    {
        path: "/referral-system/details",
        element: <DetailsPage />,
        state: "referral-system.details",
        permissions: [ROLES.admin, ROLES.manager, ROLES.client],
    },
    {
        path: "/referral-system/my-referrals",
        element: <MyReferralsPage />,
        state: "referral-system.my-referrals",
        permissions: [ROLES.admin, ROLES.manager, ROLES.client]
    },
    {
        path: "/functions",
        element: <Functions />,
        state: "functions",
        permissions: [ROLES.admin, ROLES.manager],
        sidebarProps: {
            displayText: "functions",
            icon: <SettingsIcon />,
        },
    }
];

export default appRoutes;
